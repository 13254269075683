<template>
<section class="login">
	<div class="login__background"
	     :style="{ backgroundImage: 'url(' + data.background + ')' }"
	     :class="{ login__blur: active }">
         <img src="@/assets/twologo.svg" class="login__logo">
	</div>
	<div class="login__popup">
		<form @submit.prevent="onSubmit">
			<!-- <div class="login__label">
                    <input class="login__input" type="text" v-model="" required>
                    <span class="login__floating">Login</span>
                </div> -->
			<div class="login__label">
				<input class="login__input"
				       type="password"
				       v-model="password"
				       required>
				<span class="login__floating">Password</span>
			</div>
			<input type="submit"
			       class="login__btn"
			       value="Log in">
		</form>
		<span v-if="respond.validation != null"
		      :style="{color: respond.color}">{{respond.message}}</span>
	</div>
</section>
</template>

<script>
import axios from 'axios'
export default {
	data() {
		return {
			active: false,
			password: '',
			respond: {
				validation: null,
				message: '',
				color: ''
			}
		}
	},
	methods: {
		onSubmit() {
			var bodyFormData = new FormData();
			bodyFormData.set('password', this.password);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			axios.post(process.env.VUE_APP_API + '/wp-json/better-api/v1/login', bodyFormData, config)
				.then((response) => {
					if (response.data == true) {
						this.respond = {
							validation: true,
							message: 'Password correct. Please wait for redirection.',
							color: 'green'
						}
						this.$store.commit('SET_LOGGED')
						this.$router.push('/tkp-xmas')
					} else {
						this.respond = {
							validation: false,
							message: 'Password incorrect. Please make sure you typed it right.',
							color: 'red'
						}
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	},
	created() {
		setInterval(() => {
			this.active = true
		}, 1000)
	}
}
</script>

<style scoped lang="scss">
.login {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    &__logo{
        max-width: 600px;
        width: 90%;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transition: transform 2s ease, filter 2s ease;
    }
    &__popup {
        position: absolute;
        max-width: 600px;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 50px 90px;
        background-color: #FFF;
    }
    &__label {
        position: relative;
        margin-bottom: 20px;
    }
    &__input {
        font-size: 20px;
        max-width: 600px;
        width: 100%;
        padding: 10px 0;
        font-weight: 300;
        border: 0;
        border-bottom: 1px solid #D1D1D1;
        outline: none;
        color: #8B8B8B;
        &:focus ~ .login__floating,
        &:not(:focus):valid ~ .login__floating {
            top: -14px;
            transform-origin: left;
            transform: scale(.6);
            opacity: 1;
        }
    }
    &__floating {
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 16px;
        transition: 0.2s ease all;
    }
    &__btn {
        font-size: 20px;
        background-color: transparent;
        border: 0;
        text-decoration: underline;
        padding: 0;
        font-weight: 300;
        margin-top: 20px;
        margin-bottom: 15px;
        cursor: pointer;
    }
    &__blur {
        transform: scale(1.9);
        filter: blur(15px);
    }
}
</style>
