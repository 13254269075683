<template>
    <section class="gallery">
        <transition name="fade">
            <div v-if="modal" class="gallery__index">
                <div class="gallery__lightbox"></div>
                <div class="gallery__modal">
                    <div class="gallery__modal-left">
                        <a :href="data.photos[current].url" download target="_blank" class="gallery__download-mobile"><img src="@/assets/download-black.svg"></a>
                        <button type="button" @click="modal = false" class="gallery__modal-close"><img src="@/assets/close.svg"></button>
                        <div class="gallery__arrow-left">
                            <button type="button" @click="prevPhoto"><img src="@/assets/arrow-left-white.svg"></button>
                        </div>
                        <figure>
                            <img :src="data.photos[current].sizes.large">
                        </figure>
                        <div class="gallery__arrow-right">
                            <button type="button" @click="nextPhoto"><img src="@/assets/arrow-right-white.svg"></button>
                        </div>
                    </div>
                    <div class="gallery__modal-right">
                        <div class="gallery__close">
                            <a :href="data.photos[current].url" download target="_blank" class="gallery__download"><img src="@/assets/download-black.svg"></a>
                            <button type="button" @click="modal = false"><img src="@/assets/close.svg"></button>
                        </div>
                        <div class="gallery__tiles">
                            <div
                                class="gallery__tile"
                                v-if="data.photos[current]"
                                @click="current = current"
                            >
                                <img :src="data.photos[current].url">
                            </div>
                            <div
                                class="gallery__tile"
                                v-if="data.photos[current+1]"
                                @click="current = current+1"
                            >
                                <img :src="data.photos[current+1].url">
                            </div>
                            <div
                                class="gallery__tile"
                                v-if="data.photos[current+2]"
                                @click="current = current+2"
                            >
                                <img :src="data.photos[current+2].url">
                            </div>
                            <div
                                class="gallery__tile"
                                v-if="data.photos[current+3]"
                                @click="current = current+3"
                            >
                                <img :src="data.photos[current+3].url">
                            </div>
                            <!-- <div
                                class="gallery__tile"
                                v-if="data.photos[current+4]"
                                @click="current = current+4"
                            >
                                <img :src="data.photos[current+4].url">
                            </div> -->
                        </div>
                        <div class="gallery__count">
                            <span v-html="current+1"></span> / <span v-html="arrayLength"></span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class="custom-container flex" :class="[data.reverse.includes('check') ? 'reversed' : '']">
            <div class="gallery__text">
                <h2>{{ data.title }}</h2>
            </div>
            <div class="gallery__photo">
                <div class="gallery__image">
                    <button type="button" @click="prevPhoto"><img src="@/assets/arrow-left.svg"></button>
                    <div class="gallery__photo-index" @click="modal = true">
                        <img :src="data.photos[current].sizes.large">
                    </div>
                    <button type="button" @click="nextPhoto"><img src="@/assets/arrow-right.svg"></button>
                </div>
                <div class="gallery__counter">
                    <!-- <span v-html="current+1"></span> / <span v-html="arrayLength"></span> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default{
    data(){
        return{
            current: 0,
            modal: false
        }
    },
    methods:{
        prevPhoto(){
            if (this.current-1 == -1) {
                this.current = this.arrayLength-1
            }else{
                this.current--
            }
        },
        nextPhoto(){
            if (this.current+1 > this.arrayLength-1) {
                this.current = 0
            }else{
                this.current++
            }
        }
    },
    computed:{
        arrayLength(){
            return this.data.photos.length
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.sass';

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.custom-container{
    max-width: 1100px;
    width: 100%;
    margin: 60px auto;
}

.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tablet-s{
        flex-direction: column !important;
    }
}

.reversed{
    flex-direction: row-reverse;
}

.gallery{
    border-bottom: 1px solid #D8D8D8;
    // border-top: 1px solid #D8D8D8;
    &__modal-close{
        display: none;
        @include big-tablet{
            display: block;
            position: absolute;
            z-index: 999;
            top: 25px;
            right: 20px;
        }
    }
    &__download-mobile{
        display: none;
        @include big-tablet{
            display: block;
            position: absolute;
            z-index: 999;
            top: 25px;
            left: 20px;
            width: 20px;
            height: 20px;
        }
    }
    &__photo{
        flex: 2;
    }
    &__image{
        display: flex;
        button{
            background-color: transparent;
            padding: 20px;
            border: 0;
            cursor: pointer;
            outline: none;
        }
    }
    &__text{
        margin: 0 40px;
        flex: 1;
        text-align: center;
        h2{
            font-size: 2rem;
            font-family: 'Playfair Display', serif;
            color: $granat;
            margin-bottom: 30px;
        }
    }
    &__counter{
        text-align: center;
        color: #9D9D9D;
    }
    &__lightbox{
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(234,234,234,1);
        background: -moz-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(234,234,234,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(234,234,234,1)), color-stop(20%, rgba(255,255,255,1)), color-stop(80%, rgba(255,255,255,1)), color-stop(100%, rgba(234,234,234,1)));
        background: -webkit-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(234,234,234,1) 100%);
        background: -o-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(234,234,234,1) 100%);
        background: -ms-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(234,234,234,1) 100%);
        background: linear-gradient(to right, rgba(234,234,234,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(234,234,234,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#eaeaea', GradientType=1 );
        z-index: 900;
    }
    &__index{
        z-index: 999;
    }
    &__modal{
        width: calc(100% - 160px);
        height: calc(100vh - 160px);
        position: fixed;
        top: 80px;
        left: 80px;
        background-color: #FFF;
        box-shadow: 0px 3px 6px rgba(0,0,0,.2);
        z-index: 999;
        display: flex;
        &-close{
            background: none;
            border: none;
        }
        @include mobile-l{
            width: calc(100% - 40px);
            height: calc(100vh - 40px);
            top: 20px;
            left: 20px;
        }
        &-left{
            flex: 4;
            position: relative;
            figure{
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                img{
                    position: absolute;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    top: 50%;
                    transform: translateY(-50%);
                }
                a{
                    position: absolute;
                    padding: 20px;
                    bottom: 15px;
                    right: 20px;
                    z-index: 999;
                    img{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        &-right{
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            @include big-tablet{
                display: none;
            }
        }
    }

    &__close{
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
            margin: 35px 30px;
            background-color: none;
            outline: none;
            border: none;
            cursor: pointer;
        }
        a{
            img{
                width: 20px;
                height: 20px;
                margin: 35px 40px;
            }
        }
    }
    &__photo-index{
        cursor: pointer;
        width: 100%;
        height: 360px;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__tile{
        cursor: pointer;
        margin: 20px 70px;
        @include desktop-l{
            margin: 20px 40px;
        }
    }
    &__count{
        text-align: center;
    }
    &__arrow-left, &__arrow-right{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9999;
        button{
            background-color: transparent;
            border: 0;
            padding: 30px;
            cursor: pointer;
            outline: none;
            img{
                width: 30px;
            }
        }
    }
    &__arrow-left{
        left: 0;
    }
    &__arrow-right{
        right: 0;
    }
}
</style>
