<template>
    <section
        class="section post-list"
        :class="className"
        :id="`section-${idNumber}`"
    >
        <div class="container">
            <div class="columns">
                <div class="column" v-html="data.content"></div>
            </div>
        </div>
        <div class="container">
            <div
                class="columns is-vcentered post-list__item"
                v-for="(item,key) in posts"
            >
                <div
                    class="column image-wrapper"
                    :class="[ (key % 2 == 0) ? 'is-7-widescreen' : 'is-7-widescreen is-offset-1-widescreen' ]"
                    :style="{ order: (key % 2 == 0) ? 1 : 2 }"
                >
                    <figure
                        class="image is-4by3"
                        v-if="item.thumbnail"
                    >
                        <img
                            :src="item.thumbnail"
                            :alt="item.title"
                            @click="$router.push(item.link)"
                        >
                    </figure>
                </div>
                <div
                    class="column content-wrapper"
                    :class="[ (key % 2 == 0) ? 'is-4-widescreen is-offset-1-widescreen' : 'is-4-widescreen' ]"
                    :style="{order: (key % 2 == 0) ? 2 : 1}"
                >
                    <h1
                        class="subtitle"
                        @click="$router.push(item.link)"
                        v-html="item.title"
                    ></h1>
                    <div v-html="item.excerpt"></div>
                    <a
                        :href="item.link"
                        @click.prevent="$router.push(item.link)"
                    >More</a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="columns">
                <div class="column has-text-centered">
                    <router-link :to="`/${postsPage}/`" class="button">See all Posts</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        posts() {            
            return this.$store.getters.getPosts.slice(0,3)
        },
        postsPage() {
            return this.$store.getters.getOptionsPostsPage
        }
    }
}
</script>
